<template>
    <v-navigation-drawer width="360px" height="100%" class="filterDrawer" right fixed v-model="show" app temporary>
        <v-col>
            <v-row class="justify-between">
                <v-col class="pb-0" align="right">
                    <v-icon @click="closeHandler" class="topIcons primaryColorOnHover">
                        mdi-close
                    </v-icon>
                </v-col>
            </v-row>
            <v-row class="middleRow">
                <v-col class="pl-0 pr-0">
                    <v-list-item class="pl-0 pr-0">
                        <v-expansion-panels v-model="openPanel" accordion>
                            <v-expansion-panel>
                                <v-expansion-panel-header style="background-color: #ebebeb">
                                    <v-row>
                                        <v-col>
                                            <h5 class="textDecorationBold primaryColor"
                                                style="font-size: 16px !important">
                                                Περισσότερα Φίλτρα
                                            </h5>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content align="left" class="panelOverflow">
                                    <v-row></v-row>
                                    <v-row class="ml-6 mr-6">
                                        <v-col class="pl-0 pr-0">
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0 pb-0">
                                            <h6 style="font-size: 14px !important"
                                                class="text-uppercase textDecorationBold">
                                                ΠΡΟΦΙΛ ΚΑΤΑΧΩΡΗΣΗΣ
                                            </h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0 pb-0">
                                            <v-autocomplete v-model="profilSelected" label="Αναζήτηση προφίλ"
                                                return-object :search-input.sync="searchProfil"
                                                :loading="profilAutocompleteLoader" hide-no-data clearable flat
                                                hide-selected v-on:keyup.enter="onSetFilter" attach>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ml-n6 mr-n6 mt-0">
                                        <v-col class="pl-0 pr-0">
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0 pb-0">
                                            <h6 style="font-size: 14px !important"
                                                class="text-uppercase textDecorationBold">
                                                Στοιχεια καλουντοσ
                                            </h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="callersFirstΝame" label="Όνομα" clearable
                                                color="primary" hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="callersLastName" label="Επώνυμο" clearable
                                                color="primary" hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="callersPhone" label="Τηλέφωνο" clearable
                                                color="primary" hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="callersMobile" label="Κινητό" clearable
                                                color="primary" hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="callersEmail" label="Email" clearable color="primary"
                                                hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ml-n6 mr-n6 mt-0">
                                        <v-col class="pl-0 pr-0">
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0 pb-0">
                                            <h6 style="font-size: 14px !important"
                                                class="text-uppercase textDecorationBold">
                                                Στοιχεια ΑΠΕΣΤΑΛΜΕΝΟΥ
                                            </h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="sendersFirstΝame" label="Όνομα" clearable
                                                color="primary" hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="sendersLastName" label="Επώνυμο" clearable
                                                color="primary" hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="sendersPhone" label="Τηλέφωνο" clearable
                                                color="primary" hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="sendersMobile" label="Κινητό" clearable
                                                color="primary" hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="sendersEmail" label="Email" clearable color="primary"
                                                hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ml-n6 mr-n6 mt-0">
                                        <v-col class="pl-0 pr-0">
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                    <!-- <v-row>
                                        <v-col class="pt-0 pb-0">
                                            <h6 style="font-size: 14px !important"
                                                class="text-uppercase textDecorationBold">
                                                ADMIN TOOLS
                                            </h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12" class="pt-0 pb-0">
                                            Ανατέθηκε σε
                                        </v-col>

                                        <v-col cols="12" sm="12">
                                            <v-checkbox v-model="newsletter" label="Δεν λαμβάνει newsletter"
                                                hide-details>
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ml-n6 mr-n6 mt-0">
                                        <v-col class="pl-0 pr-0">
                                            <v-divider />
                                        </v-col>
                                    </v-row> -->
                                    <v-row>
                                        <v-col class="pt-0 pb-0">
                                            <h6 style="font-size: 14px !important"
                                                class="text-uppercase textDecorationBold">
                                                {{$t("callRegistration")}}
                                            </h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-1 pb-0 pr-0 pl-1 dateClearable">
                                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                                transition="scale-transition" offset-y min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field dense outlined :placeholder="$t('from')" clearable
                                                        v-model="entryFromFormat" prepend-inner-icon="mdi-calendar"
                                                        @blur="
                                                            entryFrom = parseDate(
                                                                entryFromFormat,
                                                                'entryFrom'
                                                            )
                                                        "
                                                        v-on:keyup.enter="onSetFilter">
                                                        <template v-slot:prepend-inner>
                                                            <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker locale="el" v-model="entryFrom" scrollable
                                                    @input="menu = false">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col class="flex-grow-0 pl-0 pr-0 pb-0">
                                            <v-icon>mdi-minus</v-icon>
                                        </v-col>
                                        <v-col class="pt-1 pb-0 pl-0 pr-1 dateClearable">
                                            <v-menu ref="menu1" v-model="menu1" left :close-on-content-click="false"
                                                transition="scale-transition" offset-y min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field dense outlined clearable :placeholder="$t('toFilter')"
                                                        v-model="entryToFormat" prepend-inner-icon="mdi-calendar" @blur="
                                                            entryTo = parseDate(
                                                                entryToFormat,
                                                                'entryTo'
                                                            )
                                                        "
                                                        v-on:keyup.enter="onSetFilter">
                                                        <template v-slot:prepend-inner>
                                                            <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                                                        </template>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker locale="el" v-model="entryTo" scrollable
                                                    @input="menu1 = false">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ml-n6 mr-n6 mt-0">
                                        <v-col class="pl-0 pr-0">
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0 pb-0">
                                            <h6 style="font-size: 14px !important"
                                                class="text-uppercase textDecorationBold">
                                                {{$t("callType")}}
                                            </h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0">
                                            <v-btn-toggle v-model="callKindSelected">
                                                <v-btn v-for="button in callKindList" :key="button.text"
                                                    :value="button.val" class="ma-1 btn" outlined>
                                                    {{  $t(button.text)  }}
                                                </v-btn>
                                            </v-btn-toggle>
                                        </v-col>
                                    </v-row>

                                    <v-row class="ml-n6 mr-n6 mt-0">
                                        <v-col class="pl-0 pr-0">
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0 pb-0">
                                            <h6 style="font-size: 14px !important"
                                                class="text-uppercase textDecorationBold">
                                                {{$t("priority")}}
                                            </h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0">
                                            <v-btn-toggle v-model="prioritySelected">
                                                <v-btn v-for="button in priorityList" :key="button.text"
                                                    :value="button.val" class="ma-1 btn" outlined>
                                                    {{  $t(button.text)  }}
                                                </v-btn>
                                            </v-btn-toggle>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ml-n6 mr-n6 mt-0">
                                        <v-col class="pl-0 pr-0">
                                            <v-divider />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0 pb-0">
                                            <h6 style="font-size: 14px !important"
                                                class="text-uppercase textDecorationBold">
                                                {{$t("typeCall")}}
                                            </h6>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0">
                                            <v-btn-toggle v-model="callTypeSelected">
                                                <v-btn v-for="button in callTypeList" :key="button.text"
                                                    :value="button.val" class="ma-1 btn" outlined>
                                                    {{  $t(button.text)  }}
                                                </v-btn>
                                            </v-btn-toggle>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="callCode" :label="$t('callCode') + '...'" clearable
                                                color="primary" hide-details="auto" v-on:keyup.enter="onSetFilter"/>
                                        </v-col>
                                    </v-row>

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-footer padless absolute style="background-color: #ebebeb">
                        <v-row class="pt-5 pb-5">
                            <v-col>
                                <v-btn elevation="5" align="center" class="ma-1 btn textDecorationBold font15" color="error" @click="onSetFilter">
                                    {{  $t("search")  }}
                                </v-btn>
                            </v-col>
                            <v-col class="align-self-center font15" aling="center">
                                <a class="text-decoration-underline errorColor" @click="clearFiltersHandler">
                                    {{  $t("clear")  }}</a>
                            </v-col>
                        </v-row>
                    </v-footer>
                </v-col>
            </v-row>
        </v-col>
    </v-navigation-drawer>
</template>
  
  <script>
    /* 
    Name:SecretaryFilters
        Description: Component for the secretary filters
        API Property   
            onCloseHandler: Function on close
            filterValues: Object for the filter values
            updateAttribute: Function on update attribute
                onSearch: Function on search
    */
const isFormattedDate = (dateString) => {
    if (dateString == null) return false;
    let regEx =
        /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;
    return dateString.match(regEx) != null;
};
import SecretaryStaticLists from "@/static/secretary/secretary.js";

export default {
    name: "SecretaryFilters",
    props: {
        onCloseHandler: Function,
        filterValues: Object,
        updateAttribute: Function,
        onSearch: Function,
    },
    data() {
        return {

            callKindList: SecretaryStaticLists.callKindList,
            priorityList: SecretaryStaticLists.priorityList,
            callTypeList: SecretaryStaticLists.callTypeList,
            searchProfil: null,
            profilAutocompleteLoader: false,
            show: true,
            openPanel: 0,
            menu: false,
            menu1: false,
            entryToFormat: null,
            entryFromFormat: null,
        };
    },
    watch: {
        entryFrom() {
            this.entryFromFormat = this.formatDate(this.entryFrom);
        },
        entryTo() {
            this.entryToFormat = this.formatDate(this.entryTo);
        },
    },
    computed: {
        profilSelected: {
            get() {
                return this.filterValues?.profilSelected;
            },
            set(value) {
                this.updateAttribute("profilSelected", value);
            },
        },
        callersFirstΝame: {
            get() {
                return this.filterValues?.callersFirstΝame;
            },
            set(value) {
                this.updateAttribute("callersFirstΝame", value);
            },
        },
        callersLastName: {
            get() {
                return this.filterValues.callersLastName;
            },
            set(value) {
                this.updateAttribute("callersLastName", value);
            },
        },
        callersPhone: {
            get() {
                return this.filterValues.callersPhone;
            },
            set(value) {
                this.updateAttribute("callersPhone", value);
            },
        },
        callersMobile: {
            get() {
                return this.filterValues.callersMobile;
            },
            set(value) {
                this.updateAttribute("callersMobile", value);
            },
        },
        callersEmail: {
            get() {
                return this.filterValues.callersEmail;
            },
            set(value) {
                this.updateAttribute("callersEmail", value);
            },
        },
        sendersFirstΝame: {
            get() {
                return this.filterValues?.sendersFirstΝame;
            },
            set(value) {
                this.updateAttribute("sendersFirstΝame", value);
            },
        },
        sendersLastName: {
            get() {
                return this.filterValues.sendersLastName;
            },
            set(value) {
                this.updateAttribute("sendersLastName", value);
            },
        },
        sendersPhone: {
            get() {
                return this.filterValues.sendersPhone;
            },
            set(value) {
                this.updateAttribute("sendersPhone", value);
            },
        },
        sendersMobile: {
            get() {
                return this.filterValues.sendersMobile;
            },
            set(value) {
                this.updateAttribute("sendersMobile", value);
            },
        },
        sendersEmail: {
            get() {
                return this.filterValues.sendersEmail;
            },
            set(value) {
                this.updateAttribute("sendersEmail", value);
            },
        },
        callCode: {
            get() {
                return this.filterValues.callCode;
            },
            set(value) {
                this.updateAttribute("callCode", value);
            },
        },
        callKindSelected: {
            get() {
                return this.filterValues.callKindSelected;
            },
            set(value) {
                this.updateAttribute("callKindSelected", value);
            },
        },
        prioritySelected: {
            get() {
                return this.filterValues.prioritySelected;
            },
            set(value) {
                this.updateAttribute("prioritySelected", value);
            },
        },
        callTypeSelected: {
            get() {
                return this.filterValues.callTypeSelected;
            },
            set(value) {
                this.updateAttribute("callTypeSelected", value);
            },
        },
        entryFrom: {
            get() {
                return this.filterValues.entryFrom;
            },
            set(value) {
                this.updateAttribute("entryFrom", value);
            },
        },
        entryTo: {
            get() {
                return this.filterValues.entryTo;
            },
            set(value) {
                this.updateAttribute("entryTo", value);
            },
        },
    },
    methods: {
        closeHandler() {
            this.onCloseHandler();
        },
        onSetFilter() {
            this.onSearch("genericSearch");
        },
        clearFiltersHandler() {
            this.callersFirstΝame = null;
            this.callersLastName = null;
            this.callersPhone = null;
            this.callersMobile = null;
            this.callersEmail = null;
            this.sendersFirstΝame = null;
            this.sendersLastName = null;
            this.sendersPhone = null;
            this.sendersMobile = null;
            this.sendersEmail = null;
            this.entryFrom = null;
            this.entryTo = null;
            this.callKindSelected = null;
            this.prioritySelected = null;
            this.callTypeSelected = null;
            this.callCode = null;
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },

        parseDate(date, dateName) {
            if (!date || !isFormattedDate(date)) {
                if (dateName == "createdFrom") {
                    this.entryFrom = null;
                    this.entryFromFormat = null;
                } else if (dateName == "createdTo") {
                    this.entryTo = null;
                    this.entryToFormat = null;
                }
                return null;
            }

            const [day, month, year] = date.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },
    },
};
</script>
  
  <style lang="scss" scoped>
  .panelOverflow {
      height: calc(100vh - 278px);
      overflow: auto;
  }
  
  .panelOverflowMoreFeat {
      height: calc(100vh - 182px);
      overflow: auto;
  }
  
  h6 {
      padding: 10px 0px 10px 0px;
      font-family: CenturyGothicBold !important;
      font-weight: 500 !important;
  }
  
  .btn {
      text-transform: unset !important;
      color: #263573;
  }
  
  .topIcons {
      cursor: pointer;
      color: #263573;
  }
  
  .v-expansion-panel--active+.v-expansion-panel,
  .v-expansion-panel--active:not(:first-child) {
      margin-top: 0px;
  }
  
  .v-text-input {
      font-size: 14px;
  }
  
  .v-btn-toggle {
      border-radius: 4px;
      display: block;
      max-width: 100%;
  }
  
  .v-btn-toggle .v-btn.v-btn.v-size--default {
      min-width: 48px;
      min-height: 0;
  }
  
  .v-btn-toggle--group>.v-btn.v-btn {
      border-color: #263573 !important;
  }
  
  .v-btn-toggle>.v-btn.v-btn {
      border-radius: 5px !important;
      border-width: thin !important;
      background-color: white !important;
  }
  
  .v-input--selection-controls {
      margin: 0px 0px -16px 0px;
      padding-top: 0px;
  }
  
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
      border-color: rgba(38, 53, 115, 1) !important;
      text-transform: capitalize !important;
      font-family: CenturyGothicBold !important;
      font-weight: 500 !important;
      background-color: white;
  }
  
  .v-label {
      font-size: 13px;
  }
  
  .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
      height: 35px;
  }
  
  #active-button {
      background-color: #263573 !important;
  }
  
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
      background: #fff;
      color: #263573;
  }
  
  .primaryColor {
      color: #263573 !important;
  }
  
  .theme--light.v-btn-toggle.v-btn-toggle--group {
      background: #fff;
      color: #263573;
  }
  
  .divInputs {
      display: flex;
      padding-bottom: 10px;
  }
  
  .divInputsNoBottomPadding {
      display: flex;
      padding-bottom: 0px;
  }
  
  .v-select {
      font-size: 14px;
  }
  </style>