export default {
    filterValues: {
        profilSelected: null,
        callersFirstΝame: null,
        callersLastName: null,
        callersPhone: null,
        callersMobile: null,
        callersEmail: null,
        sendersFirstΝame: null,
        sendersLastName: null,
        sendersPhone: null,
        sendersMobile: null,
        sendersEmail: null,
        entryFrom: null,
        entryTo: null,
        callKindSelected: null,
        prioritySelected: null,
        callTypeSelected: null,
        callCode: null,
    },
    // i18n
    callKindList: [
        {
            text: "Ζήτηση",
            val: 1,
        },
        {
            text: "Ανάθεση",
            val: 2,
        },
        {
            text: "Σύσταση",
            val: 3,
        },
        {
            text: "Επικοινωνία",
            val: 4,
        },
    ],
    // i18n
    priorityList: [
        {
            text: "Κανονικό",
            val: 1,
        },
        {
            text: "Εύκολο",
            val: 2,
        },
        {
            text: "Επείγων",
            val: 3,
        },
    ],
    // i18n
    callTypeList: [
        {
            text: "Εισερχόμενη",
            val: 1,
        },
        {
            text: "Εξερχόμενη",
            val: 2,
        },
        {
            text: "Προσωρινά αποθηκευμένη",
            val: 3,
        },
    ],
    headers: [
        {
            text: "Ημερομηνία",
            align: "start",
            sortable: false,
            customSortable: false,
            sort: "",
            value: "date",
        },
        {
            text: "Από",
            align: "start",
            sortable: false,
            customSortable: true,
            sort: "",
            value: "from",
        },
        {
            text: "Είδος",
            align: "start",
            sortable: false,
            customSortable: true,
            sort: "",
            value: "kind",
        },
        {
            text: "Προς",
            align: "start",
            sortable: false,
            customSortable: true,
            sort: "",
            value: "to",
        },
        {
            text: "Προταιραιότητα",
            align: "start",
            sortable: false,
            customSortable: true,
            sort: "",
            value: "priority",
        },
        {
            text: "Επιλογές",
            align: "start",
            sortable: false,
            customSortable: false,
            sort: "",
            value: "actions",
          },
    ],
    templates: [
        {
            column: "from",
            content: "title",
            subContent: "subTitle",
            image: "isImg",
            size: 38,
            rowClass: "primaryColor",
            elementType: "avatar",
        },
        {
            column: "to",
            content: "title2",
            subContent: "subTitle2",
            image: "isImg",
            size: 38,
            rowClass: "primaryColor",
            elementType: "avatar",
        },
        {
            column: "priority",
            dataObject: "priority",
            bcolor:"bcolor",
            elementType: "textBox",
        },

    ]
}